const initFormWithRecaptcha = (formId) => {
    const getSitekey = () => {
        const hiddenInput = document.getElementById('recaptchav3_sitekey_js');
        return hiddenInput.value;
    };
    const form = document.getElementById(formId);
    form.addEventListener('submit', (e) => {
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute(getSitekey(), { action: 'submit' }).then(function (token) {
                const tokenInput = form.querySelector('.input_captcha_token_js');
                tokenInput.value = token;
                form.submit();
            });
        });
    });
};
window.initFormWithRecaptcha = initFormWithRecaptcha;
